import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('TableObject?page=' + page, {params});
    }, 
    show(id) {
        return Service().get('TableObject/show/'+id);
    },    
    create(params) {
        return Service().post('TableObject/create', params);
    },
    update(id, params) {
        return Service().post('TableObject/update/'+id, params);
    },
    delete(id) {
        return Service().post('TableObject/delete/'+id);
    },
    column_list(params) {
        return Service().get('TableObject/column-list/'+params);
    },
}