var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.authUserPermission['table-object-view'])?_c('div',[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-6 col-7"},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"})])])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header mt--4 mb--4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.tt('table_object')))])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.table.search),expression:"table.search"}],staticClass:"form-control form-control-sm text-center border-radius-20",attrs:{"type":"text","placeholder":_vm.tt('search')},domProps:{"value":(_vm.table.search)},on:{"keyup":_vm.filter,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.table, "search", $event.target.value)}}})])]),_c('div',{staticClass:"col text-right"},[(_vm.authUserPermission['table-object-create'])?_c('base-button',{attrs:{"size":"sm","type":"default"},on:{"click":_vm.create}},[_vm._v(_vm._s(_vm.tt('add_new')))]):_vm._e()],1)])]),_c('div',[(!_vm.onLoad)?_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"height":"350px","header-row-class-name":"thead-light","data":_vm.table.data}},[_c('el-table-column',{attrs:{"label":_vm.tt('code'),"prop":_vm.tt('code'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.code)+" ")]}}],null,false,3899764210)}),_c('el-table-column',{attrs:{"label":_vm.tt('object'),"prop":_vm.tt('object'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.object)+" ")]}}],null,false,2980412554)}),_c('el-table-column',{attrs:{"label":_vm.tt('attribute'),"prop":_vm.tt('attribute'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.attribute)+" ")]}}],null,false,2325043491)}),_c('el-table-column',{attrs:{"prop":"action","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.authUserPermission['table-object-update'] || _vm.authUserPermission['table-object-delete'])?_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click","hide-on-click":true},on:{"command":_vm.handleTableAction}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.authUserPermission['table-object-update'])?_c('el-dropdown-item',{attrs:{"command":{action:'edit', data:row.id}}},[_vm._v("Edit")]):_vm._e(),(_vm.authUserPermission['table-object-delete'])?_c('el-dropdown-item',{attrs:{"command":{action:'remove', data:row.id}}},[_vm._v("Delete")]):_vm._e()],1)],1):_vm._e()]}}],null,false,1968517446)})],1):_c('page-loading')],1),_c('div',{staticClass:"card-footer pb-0 "},[_c('span',{staticClass:"float-left"},[_c('base-pagination',{attrs:{"page-count":_vm.table.page.last_page,"size":"sm"},nativeOn:{"click":function($event){return _vm.changePage(_vm.table.page.current_page)}},model:{value:(_vm.table.page.current_page),callback:function ($$v) {_vm.$set(_vm.table.page, "current_page", $$v)},expression:"table.page.current_page"}})],1),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.tt('page_info', {size: (_vm.table.page.current_page - 1) * _vm.table.page.per_page + _vm.table.data.length, total: _vm.table.total}))+" ")])])])]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('modal',{attrs:{"show":_vm.form.show},on:{"update:show":function($event){return _vm.$set(_vm.form, "show", $event)}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.form.title))])]),_c('div',[_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.tt('code'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('base-input',{attrs:{"name":_vm.tt('code'),"placeholder":_vm.tt('code'),"rules":"required"},model:{value:(_vm.tableObject.code),callback:function ($$v) {_vm.$set(_vm.tableObject, "code", $$v)},expression:"tableObject.code"}}),_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.tt('object'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('base-input',{attrs:{"name":_vm.tt('object'),"placeholder":_vm.tt('object'),"rules":"required"},model:{value:(_vm.tableObject.object),callback:function ($$v) {_vm.$set(_vm.tableObject, "object", $$v)},expression:"tableObject.object"}}),_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.tt('attribute'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('base-input',{attrs:{"name":_vm.tt('attribute'),"placeholder":_vm.tt('attribute'),"rules":"required"},model:{value:(_vm.tableObject.attribute),callback:function ($$v) {_vm.$set(_vm.tableObject, "attribute", $$v)},expression:"tableObject.attribute"}})],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){_vm.form.show = false}}},[_vm._v(_vm._s(_vm.tt('close')))]),_c('base-button',{attrs:{"type":"primary","disabled":_vm.btnSave.onLoading || invalid},on:{"click":_vm.save}},[(_vm.btnSave.onLoading)?_c('span',[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(" "+_vm._s(_vm.tt('please_wait')))]):_c('span',[(_vm.form.add)?_c('span',[_vm._v(_vm._s(_vm.tt('add')))]):_c('span',[_vm._v(_vm._s(_vm.tt('edit')))])])])],1)],2)]}}],null,false,1473628431)})],1):_c('noaccess')],1)}
var staticRenderFns = []

export { render, staticRenderFns }